
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/styles';

.app {
  overflow-x: hidden;
  background-color: rgb(var(--white));

  .text,
  svg {
    vertical-align: middle;
    display: inline-block;
    // outline: 1px solid; // uncomment for debugging
  }
}

[hidden] {
  display: none !important;
}

.hidden {
  display: none !important;
}

.mobile-only {
  @include desktop {
    display: none !important;
  }
}
.desktop-only {
  @include mobile {
    display: none !important;
  }
}

@include snowGreyGridLayout();
