
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.inlineButton {
  padding: 0 10px;
  min-height: 45px;

  .icon {
    @include iconLarge(rgb(var(--jet)));
  }

  .text {
    display: none;
    font-size: var(--font-size-s);
    margin-right: 3px;

    @include wideDesktop {
      display: inline-block;
    }
  }
}
