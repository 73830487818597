
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@keyframes slide {
  0% {
    opacity: 0;
    top: -40px;
  }

  5% {
    opacity: 1;
    top: 120px;
  }

  95% {
    opacity: 1;
    top: 125px;
  }

  100% {
    top: -40px;
    opacity: 0;
  }
}

@keyframes swipe {
  0% {
    opacity: 0;
    right: -10%;
  }

  6% {
    opacity: 1;
    right: 25px;
  }

  94% {
    opacity: 1;
    right: 25px;
  }

  97% {
    opacity: 1;
    right: 40px;
  }

  100% {
    opacity: 0;
    right: -20%;
  }
}

.snackbar {
  backdrop-filter: blur(3px);
  padding: 10px 6px;
  border-radius: 8px;
  position: fixed;
  width: calc(100vw - 30px);
  max-width: 400px;
  z-index: var(--popups-index);
  background-color: rgb(var(--spanishGrey), 0.93);

  @include desktop {
    right: 25px;
    top: 80px;
    animation: swipe 9s forwards;
  }

  @include mobile {
    left: 50%;
    top: 120px;
    transform: translateX(-50%);
    animation: slide 9s forwards;
  }

  .linkContent {
    display: flex;
    width: 100%;

    .text {
      color: rgb(var(--white));
      max-width: calc(100% - 145px);
      line-height: pxToRem(20);
      margin-inline: 5px;

      .title {
        display: block;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-xs);
        margin-bottom: 3px;
      }

      .details {
        direction: ltr;
        text-align: right;
      }

      .details,
      .message {
        display: block;
        font-weight: var(--font-weight-light);
        font-size: 0.813rem;
      }

      .title,
      .details,
      .message {
        @include trim-table();
      }
    }

    .image {
      border-radius: 8px;
      overflow: hidden;
      margin-inline: 5px;
    }
  }

  .close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    cursor: pointer;

    svg {
      @include iconSmall(rgb(var(--white)));
    }
  }
}
