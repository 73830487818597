
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.y2-nanorep {
  #nanorep1 {
    #nanorep-fw {
      .widget-floating__header {
        background: #0fca80;
      }

      .widget-floating__wrapper {
        transition: bottom 0.4s !important;
        bottom: 0;

        &.dfpStickfooterUp {
          bottom: 40px !important;
        }
      }
    }
  }

  @media print {
    #nanorep1 {
      .widget-floating {
        display: none;
      }
    }
  }
}
