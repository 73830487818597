
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.header {
  position: fixed;
  width: 100vw;
  z-index: var(--header-index);
}

.headerPlaceholder {
  @include desktop {
    height: 64px;
  }
  @include mobile {
    height: 45px;
  }
}
