
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.button {
  position: absolute;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  padding: 5px;

  .heartIcon {
    position: absolute;
    background-color: rgb(var(--white));
    border-radius: 50%;
    box-shadow: var(--shadow-level-1);
    width: 30px;
    height: 30px;
    transition:
      width 1s,
      height 1s;
    background-image: url('#{$y2AssetsPath}yad2site/y2assets/images/web_heart_animation_all_platform.png');
    background-size: 4000%;
    background-position-x: -6px;
    background-position-y: -4.5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);

    &.isLiked:not(:disabled) {
      background-position: -1081.5px;
      background-position-y: -4.5px;
      &.isAnimate {
        animation: heart-burst 1s steps(26) 1;
      }
    }
  }

  &:hover {
    .heartIcon {
      background-position-x: -1123px;
    }
  }
}

@keyframes heart-burst {
  from {
    background-position-x: -6px;
  }
  to {
    background-position-x: -1081.5px;
  }
}
