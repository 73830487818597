
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.dropButton {
  color: rgb(var(--jet));
  display: flex;
  align-items: center;
  height: 100%;
  background-color: rgb(var(--white));
  padding: 0 10px;

  &:hover {
    background-color: rgb(var(--snow));
  }

  .iconBox {
    position: relative;

    .icon {
      font-size: var(--font-size-xl);

      @include wideDesktop {
        margin-left: 5px;
      }
    }
    .count {
      position: absolute;
      top: 0px;
      right: -3px;
      background-color: rgb(var(--safetyOrange));
      font-size: var(--font-size-xxxs);
      font-weight: 400;
      color: rgb(var(--white));
      padding: 0 3.5px;
      border-radius: 6px;
    }
  }
  .text {
    display: none;
    font-size: var(--font-size-s);
    white-space: nowrap;

    @include wideDesktop {
      display: unset;
    }
  }
}
