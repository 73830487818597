@import './breakpoints.scss';

@mixin snowGreyLayout {
  body {
    background: rgb(var(--snow));
    color: rgb(var(--jet));
    font-size: var(--font-size-xs);
  }
}

@mixin snowGreyGridLayout {
  body {
    direction: rtl;
    font-size: var(--font-size-xs);
    color: rgb(var(--jet));
    min-height: 100%;
    background: rgb(var(--snow));

    @include mobile {
      font-size: var(--font-size-s);
      overflow-x: hidden;
    }
  }
}
